import React, { useState, useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const currencies = [
  { id: 1, name:"STT", src: '/images/token.png', alt: 'STT' },
  { id: 2, name:"ETHER", src: '/images/polygon.webp', alt: 'ETH' },
  { id: 3, name:"EURO", src: '/images/euro.jpg', alt: 'EURO' }
];

const CurrencySelect = ({onCurrencySelection}) => {
  const [selectedImageId, setSelectedImageId] = useState(null);

  const handleImageClick = (id) => {
    let currency = currencies.find(currency => currency.id === id);

    //if(currency.name != "EURO"){
    if(currency.name == "STT"){
      setSelectedImageId(id);
		  onCurrencySelection(currency.name);
    }
    else{
      alert("Estamos trabajando para poder ofrecer esta opción pronto.");
    }
  };

  useEffect(() =>{
    setSelectedImageId(1);
  }, []);

  return (
    <Container>
      <div className="image-selector">
        {currencies.map((currency) => (
          <div
            key={currency.id}
            className={`image-container ${selectedImageId === currency.id ? 'selected' : ''}`}
            onClick={() => handleImageClick(currency.id)}
          >
            <Image src={currency.src} alt={currency.alt} thumbnail />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default CurrencySelect;
